import React, { Component } from 'react'
import { NavMenu as Header } from '../common-react-client/components/NavMenu.jsx'
import { Footer } from '../common-react-client/components/Footer.jsx'
import { Link } from 'react-router-dom'
import { Paths } from '../Constants.jsx'
import { Metadata } from '../common-react-client/components/Metadata.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import discipline from '../images/discipline.jpeg'
import action from '../images/action.jpeg'
import motivation from '../images/motivation.jpeg'

export class Scene extends Component {
  image () {
    const rand = Date.now() % 3

    if (rand === 0) {
      return discipline
    } else if (rand === 1) {
      return action
    } else {
      return motivation
    }
  }

  render () {
    const background = this.image()
    return (
      <div>
        <Metadata title='test' path='https://daam.io/x/' />
        <Header />
        <div style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'repeat',
          width: '100%',
          resizeMode: 'stretch'
        }}
        >
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
        </div>
        <br />

        <Link to={Paths.DAAM_DISCIPLINE}><FontAwesomeIcon icon='bolt' />&nbsp;D&rarr;A,A&rarr;M</Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.BOOKS_DISCIPLINE}><FontAwesomeIcon icon='book-reader' />&nbsp;Books</Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.QUOTES_DISCIPLINE}><FontAwesomeIcon icon='quote-left' />&nbsp;Quotes&nbsp;<FontAwesomeIcon icon='quote-right' /></Link>
        {/*
      {' | '}
      <Link to={Paths.PODCASTS_DISCIPLINE}>Podcasts</Link>
      {' | '}
      <Link to={Paths.VIDEOS_DISCIPLINE}>Videos</Link>
*/}
        {this.props.children}

        <Footer />
      </div>
    )
  }
}
