import React, { Component } from 'react'
import { Scene } from './Scene.jsx'

import { Col, Container, Nav, NavLink, Row } from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Routes as AppRoutes, TabGroups, Text } from '../Constants.jsx'
import { withRouter } from '../common-react-client/components/Utilities.jsx'
import { Metadata } from '../common-react-client/components/Metadata.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Daam extends Component {
  render () {
    const location = this.props.match.location.pathname
    let key = 0
    const title = 'DAAM'

    const appRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.DAAM) {
        appRoutes.push(appRoute)
      }
    })

    const daams = this.props.route.data

    return (
      <Scene>
        <Container fluid>
          <Metadata title={title} path={location} description={daams.intro.desc} keywords={daams.intro.heading} />
          <br />
          <Nav tabs>
            {
            appRoutes.map(appRoute => (<NavLink
              key={key++} tag={Link} to={appRoute.path} className={classnames({
                active: location === appRoute.path || location === appRoute.homepath
              })}
                                       >
              <FontAwesomeIcon icon='angle-double-right' />{appRoute.tabMetaTitle}
            </NavLink>))
          }
          </Nav>
          <h1>{daams.intro.heading}</h1>
          <p>{daams.intro.desc}</p>
          <br /> {
          daams.list.map(daam => (<Row key={key++}>
            <Col sm='3' />
            <Col sm='9' className='text-left'>
              <p>
                <strong>{daam.text}</strong>
                <br /> {daam.desc}
              </p>
              <hr />
            </Col>
                                  </Row>))
        }
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Daam)
