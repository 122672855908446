import React, { Component } from 'react'
// import logo from './logo.svg';
import './App.css'
import { Metadata } from './common-react-client/components/Metadata.jsx'
import { Router } from './common-react-client/components/Router.jsx'
import './fontawesome.jsx'

export class App extends Component {
  render () {
    return (
      <div className='App'>
        <Metadata />
        <Router />
      </div>
    )
  }
}
