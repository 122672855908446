import Beastoic from './scenes/Beastoic.jsx'
import Daam from './scenes/Daam.jsx'

import About from './common-react-client/scenes/About.jsx'
import Books from './common-react-client/scenes/Books.jsx'
import Contact from './common-react-client/scenes/Contact.jsx'
import NotFound from './common-react-client/scenes/NotFound.jsx'
import Podcasts from './common-react-client/scenes/Podcasts.jsx'
import Privacy from './common-react-client/scenes/Privacy.jsx'
import Quotes from './common-react-client/scenes/Quotes.jsx'
import TermsOfUse from './common-react-client/scenes/TermsOfUse.jsx'
import Videos from './common-react-client/scenes/Videos.jsx'

export const Amazon = {
  FIND_IT_URL: 'https://www.amazon.com/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=beastoic-20&linkId=5f29838b3fd21c4eca0150363af1b00f&language=en_US'
}

export const Site = {
  INSTAGRAM_URL: 'http://instagram.com/daam.io',
  TWITTER_URL: 'http://twitter.com/daam_io',
  URL: 'https://daam.io'
}

export const Text = {
  ABBREVIATION: 'DAAM',
  DESCRIPTION: 'Get the information to improve your discipline, resulting in the action you need to be motivated.',
  EMAIL_CONTACT: 'contact@daam.io',
  KEYWORDS: 'Discipline, Action, Motivation',
  TITLE: 'Discipline before Action, Action before Motivation'
}

export const TabGroups = {
  BOOKS: 'books',
  DAAM: 'daam',
  NONE: 'n/a',
  OTHER: 'other',
  PODCASTS: 'podcasts',
  QUOTES: 'quotes',
  VIDEOS: 'videos'
}

export const Paths = {
  ABOUT: '/about/',
  BEASTOIC: '/beastoic/',
  BOOKS_ACTION: '/books/action/',
  BOOKS_DISCIPLINE: '/books/discipline/',
  BOOKS_MOTIVATION: '/books/motivation/',
  CONTACT: '/contact/',
  DAAM_ACTION: '/daam/action/',
  DAAM_DISCIPLINE: '/daam/discipline/',
  DAAM_MOTIVATION: '/daam/motivation/',
  HOME: '/',
  NOT_FOUND: '/*',
  PODCASTS_ACTION: '/podcasts/action/',
  PODCASTS_DISCIPLINE: '/podcasts/discipline/',
  PODCASTS_MOTIVATION: '/podcasts/motivation/',
  PRIVACY: '/privacy/',
  QUOTES_ACTION: '/quotes/action/',
  QUOTES_DISCIPLINE: '/quotes/discipline/',
  QUOTES_MOTIVATION: '/quotes/motivation/',
  TERMS_OF_USE: '/terms_of_use/',
  VIDEOS_ACTION: '/videos/action/',
  VIDEOS_DISCIPLINE: '/videos/discipline/',
  VIDEOS_MOTIVATION: '/videos/motivation/'
}

export const Routes = [
  {
    path: Paths.ABOUT,
    component: About,
    tabGroup: TabGroups.None
  }, {
    path: Paths.BEASTOIC,
    homepath: Paths.HOME,
    component: Beastoic,
    tabGroup: TabGroups.OTHER
  }, {
    path: Paths.CONTACT,
    component: Contact,
    tabGroup: TabGroups.None
  }, {
    path: Paths.PRIVACY,
    component: Privacy,
    tabGroup: TabGroups.None
  }, {
    path: Paths.TERMS_OF_USE,
    component: TermsOfUse,
    tabGroup: TabGroups.None
  }, {
    path: Paths.BOOKS_DISCIPLINE,
    component: Books,
    data: require('./data/books-discipline.json'),
    tabGroup: TabGroups.BOOKS,
    tabMetaTitle: 'Discipline Books'
  }, {
    path: Paths.BOOKS_ACTION,
    component: Books,
    data: require('./data/books-action.json'),
    tabGroup: TabGroups.BOOKS,
    tabMetaTitle: 'Action Books'
  }, {
    path: Paths.BOOKS_MOTIVATION,
    component: Books,
    data: require('./data/books-motivation.json'),
    tabGroup: TabGroups.BOOKS,
    tabMetaTitle: 'Motivation Books'
  }, {
    path: Paths.DAAM_DISCIPLINE,
    component: Daam,
    data: require('./data/daam-discipline.json'),
    tabGroup: TabGroups.DAAM,
    tabMetaTitle: 'Discipline'
  }, {
    path: Paths.DAAM_ACTION,
    component: Daam,
    data: require('./data/daam-action.json'),
    tabGroup: TabGroups.DAAM,
    tabMetaTitle: 'Action'
  }, {
    path: Paths.DAAM_MOTIVATION,
    component: Daam,
    data: require('./data/daam-motivation.json'),
    tabGroup: TabGroups.DAAM,
    tabMetaTitle: 'Motivation'
  }, {
    path: Paths.PODCASTS_DISCIPLINE,
    component: Podcasts,
    data: require('./data/podcasts-discipline.json'),
    tabGroup: TabGroups.PODCASTS,
    tabMetaTitle: 'Discipline Podcasts'
  }, {
    path: Paths.PODCASTS_ACTION,
    component: Podcasts,
    data: require('./data/podcasts-action.json'),
    tabGroup: TabGroups.PODCASTS,
    tabMetaTitle: 'Action Podcasts'
  }, {
    path: Paths.PODCASTS_MOTIVATION,
    component: Podcasts,
    data: require('./data/podcasts-motivation.json'),
    tabGroup: TabGroups.PODCASTS,
    tabMetaTitle: 'Motivation Podcasts'
  }, {
    path: Paths.QUOTES_DISCIPLINE,
    component: Quotes,
    data: require('./data/quotes-discipline.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Discipline Quotes'
  }, {
    path: Paths.QUOTES_ACTION,
    component: Quotes,
    data: require('./data/quotes-action.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Action Quotes'
  }, {
    path: Paths.QUOTES_MOTIVATION,
    component: Quotes,
    data: require('./data/quotes-motivation.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Motivation Quotes'
  }, {
    path: Paths.VIDEOS_DISCIPLINE,
    component: Videos,
    data: require('./data/videos-discipline.json'),
    tabGroup: TabGroups.VIDEOS,
    tabMetaTitle: 'Discipline Videos'
  }, {
    path: Paths.VIDEOS_ACTION,
    component: Videos,
    data: require('./data/videos-action.json'),
    tabGroup: TabGroups.VIDEOS,
    tabMetaTitle: 'Action Videos'
  }, {
    path: Paths.VIDEOS_MOTIVATION,
    component: Videos,
    data: require('./data/videos-motivation.json'),
    tabGroup: TabGroups.VIDEOS,
    tabMetaTitle: 'Motivation Videos'
  }, {
    path: Paths.HOME,
    homepath: Paths.HOME,
    component: Daam,
    data: require('./data/daam-discipline.json'),
    tabGroup: TabGroups.None,
    tabMetaTitle: 'Welcome'
  }, {
    path: Paths.NOT_FOUND,
    component: NotFound,
    tabGroup: TabGroups.None
  }
]

export function dummy () {
  return 'dummy'
}
