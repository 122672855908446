import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import {
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faBolt,
  faBookReader,
  faLink,
  faMicrophone,
  faQuoteLeft,
  faQuoteRight,
  faUserFriends,
  faVideo
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTwitter,
  faInstagram
)

library.add(
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faBolt,
  faBookReader,
  faLink,
  faMicrophone,
  faQuoteLeft,
  faQuoteRight,
  faUserFriends,
  faVideo
)
// library.add(faTwitter, faInstagram);
